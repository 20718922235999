import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import typography from '../utils/typography';

const query = graphql`
  query NavigationQuery {
    allMarkdownRemark(
      filter: { fields: { hidePage: { ne: true } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            event
          }
        }
      }
    }
  }
`

const cssLink = (isEvent) => ({
  display: 'block',
  textDecoration: 'none',
  fontWeight: 800,
  textTransform: 'uppercase',
  color: isEvent ? process.env.HIGHLIGHT : process.env.YELLOW,
  margin: `0 ${typography.rhythm(0.5)}`
})

const Template = ({ style }) => (
  <StaticQuery
    query={query}
    render={({ allMarkdownRemark: posts }) => (
      <nav css={style}>
        {posts.edges.map(({ node }) => (
          <Link key={node.id} css={cssLink(node.frontmatter.event)} to={node.fields.slug}>
            {node.frontmatter.title}
          </Link>
        ))}
      </nav>
    )}
  />
)


export default Template;