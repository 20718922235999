import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import PageHeader from './page-header'
import Edge from './edge'
import Footer from './footer'
import typography from '../utils/typography'
import Helmet from 'react-helmet'

import './layout.css'

const columnStyle = {
  background: 'white',
  paddingTop: typography.rhythm(1.5),
  marginTop: `-${typography.rhythm(1.5)}`,
  marginBottom: `-${typography.rhythm(1.5)}`,
  zIndex: 1,
  position: 'relative'
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          {/* inline style elements */}
          <style type="text/css">{`
              body {
                  background-color: ${process.env.BLUE_BLACK};
              }
              .media {
                background: ${process.env.BLUE};
                margin: 0 -10px 20px;
                height: ${typography.rhythm(10)};
                position: relative;
              }
              .media iframe {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
              }
          `}</style>
        </Helmet>
        <PageHeader siteTitle={data.site.siteMetadata.title} />
        <div style={{ background: process.env.BLUE_DIM, border: '0.1px solid transparent' }}>
          <Edge style={columnStyle}>{children}</Edge>
        </div>
        <div style={{ marginTop: typography.rhythm(1.5) }}>
          <Footer>
            <Edge style={{ width: '100%', textAlign: 'right' }}>
              <div>MUNDI DANCE - {new Date().getFullYear()}</div>
            </Edge>
          </Footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
