import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.666,
  headerFontFamily: [
    'Montserrat',
    'sans-serif'
  ],
  bodyFontFamily: ['Montserrat', 'sans-serif'],
  headerWeight: 800,
  bodyWeight: 500,
  scaleRatio: 2,
  googleFonts: [
    {
      name: 'Montserrat',
      styles: ['500', '800'],
    },
  ],
  overrideStyles: (fn, options, styles) => ({
    'body': {
      color: process.env.BLUE_BLACK,
    },
    'h1': {
      textTransform: 'uppercase'
    },
    'h3,h4,h5,h6': {
      ...fn.adjustFontSizeTo(options.baseFontSize)
    },
    'hr': {
      position: 'relative',
      backgroundColor: 'transparent',
    }
  })
  // See below for the full list of options.
})

export default typography
