import React from 'react'
import PropTypes from 'prop-types'
import typography from '../utils/typography'
import cn from 'classnames'

const css = {
  padding: `0 ${typography.rhythm(1)}`,
  margin: '0 auto',
  maxWidth: typography.rhythm(30),
}

const Edge = ({ style, className, children }) => (
  <div style={style} className={cn('Edge', className)} css={css}>
    {children}
  </div>
)

Edge.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default Edge
