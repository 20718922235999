import React from 'react'

const Logo = ({ color }) => (
  <div css={{ fontSize: 0 }}>
    <svg viewBox='0 0 443 42' xmlns='http://www.w3.org/2000/svg' fillRule='evenodd'
      fill={color}
      clipRule='evenodd' strokeLinejoin='round' strokeMiterlimit='1.414'>
      <g fillRule='nonzero'>
        <path d='M47.824,40.764l-0.114,-39.853l-11.045,0l-12.639,21.236l-12.981,-21.236l-11.045,0l0,39.853l12.241,0l0,-17.535l8.654,13.949l5.921,0l8.654,-14.575l0.113,18.161l12.241,0Z'
        />
        <path d='M73.387,41.675c12.241,0 19.415,-6.889 19.415,-18.845l0,-21.919l-13.209,0l0,21.521c0,6.092 -2.334,8.369 -6.092,8.369c-3.757,0 -6.092,-2.277 -6.092,-8.369l0,-21.521l-13.436,0l0,21.919c0,11.956 7.174,18.845 19.414,18.845Z'
        />
        <path d='M125.14,0.911l0,18.105l-15.144,-18.105l-11.045,0l0,39.853l13.094,0l0,-18.104l15.145,18.104l11.045,0l0,-39.853l-13.095,0Z'
        />
        <path d='M144.725,40.764l19.642,0c13.323,0 22.489,-7.572 22.489,-19.926c0,-12.355 -9.166,-19.927 -22.489,-19.927l-19.642,0l0,39.853Zm13.436,-10.475l0,-18.902l5.637,0c5.636,0 9.508,3.359 9.508,9.451c0,6.092 -3.872,9.451 -9.508,9.451l-5.637,0Z'
        />
        <rect x='191.752' y='0.911' width='13.436' height='39.853' />
        <path d='M228.759,40.764l19.642,0c13.323,0 22.489,-7.572 22.489,-19.926c0,-12.355 -9.166,-19.927 -22.489,-19.927l-19.642,0l0,39.853Zm13.436,-10.475l0,-18.902l5.637,0c5.636,0 9.508,3.359 9.508,9.451c0,6.092 -3.872,9.451 -9.508,9.451l-5.637,0Z'
        />
        <path d='M305.05,40.764l13.892,0l-17.422,-39.853l-13.208,0l-17.422,39.853l13.664,0l2.676,-6.945l15.144,0l2.676,6.945Zm-14.119,-16.624l3.871,-10.02l3.871,10.02l-7.742,0Z'
        />
        <path d='M347.295,0.911l0,18.105l-15.145,-18.105l-11.045,0l0,39.853l13.095,0l0,-18.104l15.144,18.104l11.045,0l0,-39.853l-13.094,0Z'
        />
        <path d='M387.49,41.675c7.8,0 13.949,-2.846 17.877,-7.97l-8.483,-7.572c-2.334,2.96 -5.124,4.668 -8.711,4.668c-5.579,0 -9.337,-3.871 -9.337,-9.963c0,-6.092 3.758,-9.964 9.337,-9.964c3.587,0 6.377,1.708 8.711,4.669l8.483,-7.572c-3.928,-5.124 -10.077,-7.971 -17.877,-7.971c-12.81,0 -22.204,8.597 -22.204,20.838c0,12.24 9.394,20.837 22.204,20.837Z'
        />
        <path d='M422.447,30.63l0,-5.238l16.852,0l0,-9.678l-16.852,0l0,-4.669l19.187,0l0,-10.134l-32.396,0l0,39.853l33.136,0l0,-10.134l-19.927,0Z'
        />
      </g>
    </svg>
  </div>
)

Logo.defaultProps = {
  color: 'red'
}

export default Logo