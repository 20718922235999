import { Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import Edge from './edge'
import Logo from './logo'
import Navigation from './navigation'
import { graphql } from 'gatsby'

import typography from '../utils/typography'
import { LAYOUT_WIDTH } from '../variables';

const header = {
  background: process.env.BLUE_BLACK,
}

const edgeStyle = {
  display: "grid",
  gridTemplateColumns: `1fr minmax(100px, 200px)`,
  gridGap: `0 ${typography.rhythm(1)}`,
  gridTemplateAreas: "left right",
  alignItems: 'end',
}

const bandStyle = {
  height: typography.rhythm(3),
  background: process.env.GREEN
}

const bandTopStyle = {
  marginTop: `-${typography.rhythm(1)}`,
  height: typography.rhythm(1),
  background: process.env.BLUE_HI
}

const headerText = {
  margin: `${typography.rhythm(0.5)} 0 ${typography.rhythm(1)} 0`,
  fontWeight: 'normal',
  textDecoration: 'none',
  color: process.env.YELLOW,
  display: 'flex',
  textTransform: 'uppercase',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const navigation = {
  margin: `0 -${typography.rhythm(0.5)} ${typography.rhythm(2)}`,
  display: 'flex',
  [`@media (max-width: ${typography.rhythm(LAYOUT_WIDTH)})`]: {
    flexDirection: 'column',
  }
}

const headerContent = {
  margin: `${typography.rhythm(2)} 0 0`,
  textDecoration: 'none',
  [`@media (max-width: ${typography.rhythm(LAYOUT_WIDTH)})`]: {
    flexDirection: 'column',
  }
}

const hideSmall = {
  [`@media (max-width: ${typography.rhythm(LAYOUT_WIDTH)})`]: {
    display: 'none'
  }
}

const hideLarge = {
  marginTop: typography.rhythm(1),
  marginBottom: typography.rhythm(1),
  display: 'none',
  [`@media (max-width: ${typography.rhythm(LAYOUT_WIDTH)})`]: {
    display: 'block'
  }
}

const PageHeader = () => <StaticQuery query={query} render={({ file }) => (
  <header css={header}>

    <Edge css={hideLarge}>
      <Link
        style={{ textDecoration: 'none' }}
        to="/"
      >
        {/* {siteTitle} */}
        <Logo color={process.env.YELLOW} />
      </Link>
        <h3 css={headerText} style={{ textAlign: 'center', flexDirection: 'column', margin: `${typography.rhythm(0.5)} 0 0` }}>
          Contemporary African&nbsp;Dance
        </h3>
    </Edge>

    <Edge css={edgeStyle}>
      <div css={headerContent}>
        <Link
          css={hideSmall}
          style={{ textDecoration: 'none' }}
          to="/"
        >
          {/* {siteTitle} */}
          <Logo color={process.env.YELLOW} />
        </Link>
        <h3 css={{...headerText, ...hideSmall}}>
          Contemporary African&nbsp;Dance
        </h3>

        <Navigation style={navigation} />
      </div>

      <div css={{ marginTop: `-${typography.rhythm(2)}`, fontSize: 0 }}>
        <Img fluid={file.childImageSharp.fluid} />
      </div>
    </Edge>
    <div css={bandTopStyle}></div>
    <div css={bandStyle}></div>
  </header>
)} />

PageHeader.propTypes = {
  siteTitle: PropTypes.string,
}

PageHeader.defaultProps = {
  siteTitle: ``,
}

export const query = graphql`
query {
    file(relativePath: { eq: "louis-mono-side-on.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(
          maxWidth: 270,
          maxHeight: 380,
          duotone: {
            highlight: "#00E0AD",
            shadow: "#16324c"
          }
          traceSVG: {
            color: "#16324c",
            turdSize: 1.5
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default PageHeader
