import React from 'react'
import typography from '../utils/typography'

const style = {
  padding: '0 2rem',
  margin: '0 auto',
  color: process.env.GREEN,
  background: process.env.BLUE_BLACK,
  minHeight: typography.rhythm(3),
  display: 'flex',
  alignItems: 'center',
}

const Footer = ({ children }) => (
  <footer css={style}>
    {children}
  </footer>
)

export default Footer
